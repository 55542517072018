import Reveal, { RevealProps } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import React from "react";
type Props = RevealProps & {
  enabled?: boolean;
  children: React.ReactNode;
};
const fadeOpacity = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;
const fadeUp = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0px, 10px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;
const fadeDown = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -10px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;
const fadeRight = keyframes`
  from {
    opacity: 0;
    transform: translate3d(-10px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;
const fadeLeft = keyframes`
  from {
    opacity: 0;
    transform: translate3d(10px, 0px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;
export const FadeUp: React.FC<Props> = props => {
  return <Fader keyframes={fadeUp} damping={0.2} triggerOnce {...props} data-sentry-element="Fader" data-sentry-component="FadeUp" data-sentry-source-file="Fader.tsx">
      {props.children}
    </Fader>;
};
export const FadeDown: React.FC<Props> = props => {
  return <Fader keyframes={fadeDown} damping={0.2} triggerOnce {...props} data-sentry-element="Fader" data-sentry-component="FadeDown" data-sentry-source-file="Fader.tsx">
      {props.children}
    </Fader>;
};
export const FadeRight: React.FC<Props> = props => {
  return <Fader keyframes={fadeRight} damping={0.2} triggerOnce {...props} data-sentry-element="Fader" data-sentry-component="FadeRight" data-sentry-source-file="Fader.tsx">
      {props.children}
    </Fader>;
};
export const FadeLeft: React.FC<Props> = props => {
  return <Fader keyframes={fadeLeft} damping={0.2} triggerOnce {...props} data-sentry-element="Fader" data-sentry-component="FadeLeft" data-sentry-source-file="Fader.tsx">
      {props.children}
    </Fader>;
};
export const Fade: React.FC<Props> = props => {
  return <Fader keyframes={fadeOpacity} damping={0.2} triggerOnce {...props} data-sentry-element="Fader" data-sentry-component="Fade" data-sentry-source-file="Fader.tsx">
      {props.children}
    </Fader>;
};
export const Fader: React.FC<Props> = ({
  enabled = true,
  children,
  className,
  ...props
}) => {
  if (enabled) return <Reveal className={className} {...props as any}>
        {children}
      </Reveal>;
  return <>
      {React.Children.map(children, (child, index) => <div className={className} key={index}>
          {child}
        </div>)}
    </>;
};

/**
- Fader
  - A
  - B
===

- Fader
  - div
    - A
*/